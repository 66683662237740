import Plyr from 'plyr/src/js/plyr';

const Default = {
  controls: [
    'play',
    'progress',
    'current-time',
    'mute',
    'volume'
  ],
  invertTime: false
};

function initVideoPlayer(element, options) {
  return new Plyr(element, Object.assign(Default, options));
}

export default initVideoPlayer;
