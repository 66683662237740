// eslint-disable-next-line no-undef,camelcase
__webpack_public_path__ = window.STATIC_URL || '/';

import './vendor/bootstrap';

import initVideoPlayer from './lib/video';

/**
 * ------------------------------------------------------------------------
 * Main application
 * ------------------------------------------------------------------------
 */
window.addEventListener('load', () => {
  document.documentElement.classList
    .remove('no-js');

  // évite de passer par un CDN tiers
  const plyrOptions = typeof window.STATIC_URL === 'undefined' ?
    {} :
    {
      blankUrl: `${window.STATIC_URL}vendor/plyr/blank.mp4`,
      iconUrl: `${window.STATIC_URL}vendor/plyr/plyr.svg`
    };

  document.querySelectorAll('.video-player')
    .forEach(el => initVideoPlayer(el, plyrOptions));
});
