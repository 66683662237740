// -----------------------------------------------------------------------------
// Bootstrap v5
// -----------------------------------------------------------------------------
// see: https://getbootstrap.com/docs/5.0/getting-started/javascript/
import 'bootstrap/alert';
import 'bootstrap/button';
// import 'bootstrap/carousel';
import 'bootstrap/collapse';
// import 'bootstrap/dropdown';
// import 'bootstrap/modal';
// import 'bootstrap/scrollspy';
// import 'bootstrap/tab';
